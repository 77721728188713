/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../services/API';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import './styles.css';
import {
  atualizarPerfil,
  atualizarSaldo,
  resgatarPremio,
} from '../../../store/modules/parceiro/actions';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { ConfimarEndereco } from './ConfimarEndereco';

export default function DetalhePremio() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const resgatando = useSelector((state) => state.parceiro.resgatando);
  const cartao = useSelector((state) => state.parceiro.cartao);
  const parceiro = useSelector((state) => state.parceiro);
  const [carregando, setCarregando] = useState(false);
  const [disponibilidade, setDisponibilidade] = useState({
    disponibilidade: false,
    foraDeLinha: false,
    idLoja: null,
    pontos: 0,
  });
  const [premio, setPremio] = useState({
    nome: '',
    fabricante: '',
    descricao: '',
    diretorioFoto: '',
    caracteristicas: [],
    fichaTecnica: [],
  });
  const [varianteSelecionada, setVarianteSelecionada] = useState(0);
  const [qtde, setQtde] = useState(1);
  const [celularRecarga, setCelularRecarga] = useState('');
  const [operadoraRecarga, setOperadoraRecarga] = useState('');
  const [nomeCompleto, setNomeCompleto] = useState('');
  const [confirmar, setConfirmar] = useState(false);
  const [cartaoIfood, setCartaoIfood] = useState(false);
  const [tipoChavePix, setTipoChavePix] = useState('');
  const [chavePix, setChavePix] = useState('');

  const resgatar = async (enderecoEntrega) => {
    let obs = `MODELO: ${premio.nome}, TIPO: ${premio.tipo}`;
    if (premio.tipo === 'recarga' && !cartaoIfood) {
      if (!operadoraRecarga) {
        toast.error('Selecione a operadora', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        setConfirmar(false);
        return;
      }
      if (!celularRecarga) {
        toast.error('Informe o celular', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        setConfirmar(false);
        return;
      }
      obs = `${operadoraRecarga} - ${celularRecarga}`;
    } else if (premio.tipo === 'recarga' && cartaoIfood) {
      obs = null;
    } else if (premio.tipo === 'pix') {
      if (!tipoChavePix) {
        toast.error('Selecione o tipo de chave', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        setConfirmar(false);
        return;
      }
      if (!chavePix) {
        toast.error('Informe a chave Pix', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        setConfirmar(false);
        return;
      }
      obs = `${tipoChavePix} - ${chavePix}`;
    } else if (premio.tipo === 'credito' && !cartao) {
      if (!nomeCompleto) {
        toast.error('Informe seu nome completo', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        setConfirmar(false);
        return;
      }
      obs = nomeCompleto;
    } else if (premio.tipo === 'credito' && cartao) {
      obs = null;
    }

    setConfirmar(false);
    dispatch(
      resgatarPremio({
        codigoPremio: premio.codigo,
        qtde,
        obs,
        enderecoEntrega,
      })
    );
  };

  const updateUser = (endereco, completo) => {
    if (!completo) dispatch(atualizarPerfil(endereco));
  };

  const alterarVariante = (valor) => {
    setVarianteSelecionada(Number(valor));
  };

  const addQtde = (e) => {
    e.preventDefault();
    setQtde(qtde + 1);
  };

  const subQtde = (e) => {
    if (qtde === 1) return;
    e.preventDefault();
    setQtde(qtde - 1);
  };

  const validarCelular = async (e) => {
    const valor = e.target.value.replace(/[^\d]/g, '');
    if (!valor) return;
    if (valor.length < 10) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCelularRecarga('');
    }
  };

  const buscarPremio = async () => {
    setCarregando(true);
    const resultado = await api.get(`/api/premios/${id}`);
    if (resultado) {
      const premio = resultado.data;
      if (premio.nome.toLowerCase().includes('ifood')) setCartaoIfood(true);
      setPremio(premio);
      setCarregando(false);
    }
  };

  useEffect(() => {
    buscarPremio();
    dispatch(atualizarSaldo(parceiro.cpf));
  }, []);

  useEffect(() => {
    buscarPremio();
  }, [qtde]);

  return (
    <Container className="mt-5 mb-5">
      {carregando && (
        <div className="d-flex justify-content-center align-items-center">
          <span className="premio-status-carregando">Carregando</span>
        </div>
      )}
      {!carregando && !confirmar && !resgatando ? (
        <>
          <Row>
            <Col xs={12} lg={5} className="p-3">
              <Carousel className="detalhe" interval={3000}>
                <Carousel.Item>
                  <img className="d-block w-100" src={premio.diretorioFoto} />
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col
              xs={12}
              lg={7}
              id="detalheProduto"
              name={premio.codigo}
              className="p-3"
            >
              <Col>
                <span id="nomeProduto" className="premio-titulo">
                  {premio.nome}
                </span>
              </Col>
              <br />
              {premio.tipo === 'credito' ||
                (premio.tipo === 'recarga' && (
                  <Col>
                    <span>
                      <b>{`${
                        Number(premio?.pontos).toFixed(0) * qtde
                      } pontos`}</b>
                    </span>
                  </Col>
                ))}
              <br />
              <Col>
                <span className="premio-subtitulo">
                  <b>Descrição</b>
                </span>
              </Col>
              <Col className="text-justify">
                <span
                  className="d-inline-block premio-subtitulo"
                  dangerouslySetInnerHTML={{ __html: premio.descricao }}
                ></span>
              </Col>
              <br />
              <Col>
                <span className="premio-subtitulo">
                  <b>Fabricante</b>
                </span>
              </Col>
              <Col className="text-justify">
                <span className="d-inline-block premio-subtitulo">
                  {premio.fabricante}
                </span>
              </Col>
              <br />
              <Col className="text-justify">
                <button className="btn-sub-qtde m-1" onClick={subQtde}>
                  <i className="fas fa-minus"></i>
                </button>
                <span className="premio-subtitulo m-3">
                  <b>{qtde}</b>
                </span>
                <button className="btn-add-qtde m-1" onClick={addQtde}>
                  <i className="fas fa-plus"></i>
                </button>
              </Col>
              <br />
              {premio.tipo === 'recarga' && !cartaoIfood && (
                <form className="form loja fundo-claro">
                  <Col className="d-flex flex-column" xs={8} sm={6}>
                    <label htmlFor="operadora">Operadora</label>
                    <select
                      id="operadora"
                      name="operadora"
                      placeholder="Operadora"
                      value={operadoraRecarga}
                      onChange={(e) => setOperadoraRecarga(e.target.value)}
                    >
                      <option disabled value="">
                        Selecione a operadora
                      </option>
                      <option value="Vivo">Vivo</option>
                      <option value="Claro">Claro</option>
                      <option value="TIM">TIM</option>
                      <option value="OI">OI</option>
                      <option value="Nextel">Nextel</option>
                      <option value="Algar">Algar</option>
                    </select>
                  </Col>
                  <Col className="d-flex flex-column" xs={8} sm={6}>
                    <label htmlFor="celular">Celular</label>
                    <InputMask
                      id="celular"
                      name="celular"
                      type="text"
                      placeholder="Celular"
                      value={celularRecarga}
                      mask="(99) 99999-9999"
                      onChange={(e) => setCelularRecarga(e.target.value)}
                      onBlur={validarCelular}
                    ></InputMask>
                  </Col>
                </form>
              )}
              {premio.tipo === 'pix' && (
                <form className="form loja fundo-claro">
                  <Col className="d-flex flex-column" xs={8} sm={6}>
                    <label htmlFor="tipoChavePix">Tipo de Chave</label>
                    <select
                      id="tipoChavePix"
                      name="tipoChavePix"
                      placeholder="Tipo de Chave"
                      value={tipoChavePix}
                      onChange={(e) => setTipoChavePix(e.target.value)}
                    >
                      <option disabled value="">
                        Selecione o Tipo de Chave Pix
                      </option>
                      <option value="CPF">CPF</option>
                      <option value="CNPJ">CNPJ</option>
                      <option value="Telefone">Telefone</option>
                      <option value="Email">Email</option>
                      <option value="Chave Aleatória">Chave Aleatória</option>
                    </select>
                  </Col>
                  <Col className="d-flex flex-column" xs={8} sm={6}>
                    <label htmlFor="chavePix">Chave Pix</label>
                    <input
                      id="chavePix"
                      name="chavePix"
                      type="text"
                      placeholder="Chave Pix"
                      value={chavePix}
                      onChange={(e) => setChavePix(e.target.value)}
                    ></input>
                  </Col>
                </form>
              )}
              {premio.tipo === 'credito' && !cartao && (
                <>
                  <Col>
                    <span className="premio-subtitulo">
                      <b>Cartão Mastercard® Kulzer</b>
                    </span>
                  </Col>

                  <Col className="text-justify">
                    <span>
                      Você ainda não possui um cartão Mastercard® Kulzer.
                    </span>
                    <br />
                    <span>
                      Será enviado um envelope contendo o seu cartão Clube
                      Kulzer, já com o valor deste resgate.
                    </span>
                    <br />
                    <span>
                      Após desbloquear seu cartão guarde-o para seus próximos
                      resgates. É permitido 1 (um) cartão por CPF.
                    </span>
                    <br />
                    <span>
                      Os próximos resgates em dinheiro, serão creditados
                      automaticamente neste mesmo cartão.
                    </span>
                    <br />
                    <span>
                      Por medida de segurança seu cartão estará bloqueado, após
                      receber o envelope, siga as instruções para desbloquear o
                      seu cartão.
                    </span>
                    <br />
                    <span>
                      O desbloqueio do seu cartão pode levar até 24h úteis após
                      a solicitação.
                    </span>
                    <br />
                    <span>
                      Em caso de perda ou roubo, solicite o bloqueio do seu
                      cartão.
                    </span>
                    <br />
                    <br />
                    <span>
                      Importante verificar se o nome digitado é o do titular do
                      CPF cadastrado. Caso o nome esteja incorreto, a
                      MASTERCARD® solicitará os dados novamente.
                    </span>
                    <br />
                    <br />
                  </Col>

                  <form className="form loja fundo-claro">
                    <Col className="d-flex flex-column" xs={8} sm={6}>
                      <label for="nome">Digite seu nome</label>
                      <input
                        type="text"
                        id="nomeCompleto"
                        placeholder="Nome Completo"
                        value={nomeCompleto}
                        onChange={(e) => setNomeCompleto(e.target.value)}
                      ></input>
                    </Col>
                  </form>
                </>
              )}

              {premio.tipo === 'credito' &&
                cartao &&
                cartao.numero &&
                cartao.bloqueado && (
                  <>
                    <Col>
                      <span className="premio-subtitulo">
                        <b>Cartão Mastercard® Kulzer</b>
                      </span>
                    </Col>

                    <Col className="text-justify">
                      <span>
                        SEU CARTÃO MASTERCARD® KG POINTS JÁ ESTÁ A CAMINHO.
                      </span>
                      <br />
                      <br />
                      <span>
                        Sua carga será depositada diretamente no cartão com
                        final: {cartao.numero.toString().slice(-4)}
                      </span>
                      <br />
                      <br />
                      <span>
                        Os próximos resgates em dinheiro serão creditados nesse
                        mesmo cartão. Continue resgatando normalmente.
                      </span>
                      <br />
                      <br />
                      <span>
                        Após desbloquear seu cartão guarde-o para seus próximos
                        resgates. É permitido 1 (um) cartão por CPF.
                      </span>
                      <br />
                      <br />
                      <span>Seu cartão é pessoal e intransferível.</span>
                    </Col>
                  </>
                )}

              {premio.tipo === 'credito' &&
                cartao &&
                cartao.numero &&
                !cartao.bloqueado && (
                  <>
                    <Col>
                      <span className="premio-subtitulo">
                        <b>Cartão Mastercard® Kulzer</b>
                      </span>
                    </Col>

                    <Col className="text-justify">
                      <span>Você já possui um cartão Mastercard® Kulzer</span>
                      <br />
                      <br />
                      <span>
                        Sua carga será depositada diretamente no cartão com
                        final: {cartao.numero.toString().slice(-4)}
                      </span>
                      <br />
                      <br />
                      <span>
                        Após desbloquear seu cartão guarde-o para seus próximos
                        resgates. É permitido 1 (um) cartão por CPF.
                      </span>
                      <br />
                      <br />
                      <span>Seu cartão é pessoal e intransferível.</span>
                    </Col>
                  </>
                )}
              {!carregando && premio.pontos && (
                <Col className="pt-5">
                  <button
                    className="btn-resgatar"
                    onClick={() => setConfirmar(true)}
                  >
                    <i className="fas fa-shopping-cart"></i> RESGATAR
                  </button>
                </Col>
              )}
            </Col>
          </Row>
          {(premio.caracteristicas || premio.fichaTecnica) && (
            <Row>
              <Col xs={12}>
                <Col className="mb-5">
                  <span className="premio-subtitulo">
                    <b>Características</b>
                  </span>

                  <table className="mt-3">
                    {premio.caracteristicas?.map((caracteristica) => (
                      <tr>
                        <td className="text-left p-2">
                          <b>{caracteristica.Descricao}</b>
                        </td>
                        {[
                          'Observações',
                          'Características Gerais',
                          'Dicas de Utilização',
                          'Descrição',
                        ].includes(caracteristica.Descricao) && (
                          <td
                            className="text-justify p-2"
                            dangerouslySetInnerHTML={{
                              __html: caracteristica.Valor,
                            }}
                          ></td>
                        )}
                        {![
                          'Observações',
                          'Características Gerais',
                          'Dicas de Utilização',
                          'Descrição',
                        ].includes(caracteristica.Descricao) && (
                          <td className="text-justify p-2">
                            {caracteristica.Valor}
                          </td>
                        )}
                      </tr>
                    ))}
                  </table>
                </Col>
              </Col>
              <Col xs={12}>
                <Col className="mb-5">
                  <span className="premio-subtitulo">
                    <b>Especificações Técnicas</b>
                  </span>

                  <table className="mt-3">
                    {premio.fichaTecnica?.map((caracteristica) => (
                      <tr>
                        <td className="text-left p-2">
                          <b>{caracteristica.Descricao}</b>
                        </td>
                        {[
                          'Observações',
                          'Características Gerais',
                          'Dicas de Utilização',
                          'Descrição',
                        ].includes(caracteristica.Descricao) && (
                          <td
                            className="text-justify p-2"
                            dangerouslySetInnerHTML={{
                              __html: caracteristica.Valor,
                            }}
                          ></td>
                        )}
                        {![
                          'Observações',
                          'Características Gerais',
                          'Dicas de Utilização',
                          'Descrição',
                        ].includes(caracteristica.Descricao) && (
                          <td className="text-justify p-2">
                            {caracteristica.Valor}
                          </td>
                        )}
                      </tr>
                    ))}
                  </table>
                </Col>
              </Col>
            </Row>
          )}
        </>
      ) : null}
      {!carregando && confirmar && !resgatando ? (
        <Row>
          <Col xs={12} lg={6} className="p-3">
            <Carousel className="detalhe" interval={3000}>
              <Carousel.Item>
                <img className="d-block w-100" src={premio.diretorioFoto} />
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col
            xs={12}
            lg={6}
            name={premio.codigo}
            className="d-flex justify-content-center align-items-center p-3 flex-column"
          >
            <ConfimarEndereco
              state={parceiro}
              premio={premio}
              qtde={qtde}
              resgatar={resgatar}
              setConfirmar={setConfirmar}
              updateUser={updateUser}
            />
          </Col>
        </Row>
      ) : null}
      {!carregando && resgatando && !confirmar ? (
        <Row>
          <Col xs={12} lg={6} className="p-3"></Col>
          <Col
            xs={12}
            lg={6}
            className="d-flex justify-content-center align-items-center p-3 flex-column"
          >
            <span className="p-2">
              Resgatando <i className="fas fa-circle-notch fa-spin"></i>
            </span>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
}
